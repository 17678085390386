import React from "react";

import users from "../../assets/images/trainings/users.png";
import target from "../../assets/images/trainings/target.png";
import file from "../../assets/images/trainings/file.png";
import checklist from "../../assets/images/trainings/checklist.png";
import medal from "../../assets/images/trainings/medal.png";

const Slide2 = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-12 mt-3">
          <div
            className="p-3"
            style={{
              backgroundColor: "#FBA049",
              color: "white",
              height: "100%",
            }}
          >
            <div className="title d-flex align-items-center justify-content-end">
              <h4 className="d-inline mb-0 fw-bold">الفئة المستهدفة</h4>
              <img
                style={{ width: "30px", marginLeft: "10px" }}
                src={target}
                alt=""
              />
            </div>
            <p className="text-white fw-normal py-3">
            الموظفين ذو خبرة سنتين كحد أدنى في الشركات العاملة في قطاع الترفيه
            </p>
           
          </div>
        </div>

        <div className="col-lg-6 col-12 mt-3">
          <div
            className="p-3"
            style={{
              backgroundColor: "#FBA049",
              color: "white",
              height: "100%",
            }}
          >
            <div className="title d-flex align-items-center justify-content-end">
              <h4 className="d-inline mb-0 fw-bold">نبذة عن البرنامج</h4>
              <img
                style={{ width: "20px", marginLeft: "10px" }}
                src={file}
                alt=""
              />
            </div>
            <p className="text-white fw-normal py-3" dir="rtl">
            برنامـــج تدريـبي مكـــثف مـــدته 5 أشــهر تهدف الهيئة العامة للترفيه إلى تمكين القطاع من خلال تنفيذ ورش عمل تأهيلية وتطويرية تتضمن زيارات ميدانية لوجهات عالمية تستهدف العاملين في مجال الترفيه على ثلاث مسارات وهي: 
            
              <ul style={{ direction: "rtl", listStylePosition: "inside" }}>
              <li>تصميم التجارب الترفيهية</li>

                <li>الصحة والسلامة والتشغيل في الفعاليات</li>
              </ul>
            </p>
          </div>
        </div>

        <div className="col-lg-6 col-12 mt-3">
          <div
            className="p-3"
            style={{
              backgroundColor: "#FBA049",
              color: "white",
              height: "100%",
            }}
          >
            <div className="title d-flex align-items-center justify-content-end">
              <h4 className="d-inline mb-0 fw-bold">مزايا البرنامج</h4>
              <img
                style={{ width: "20px", marginLeft: "10px" }}
                src={medal}
                alt=""
              />
            </div>
            <p className="text-white fw-normal py-3">
              <ul style={{ direction: "rtl", listStylePosition: "inside" }}>
               <li>مكتبة إلكترونية شاملة متاحة طوال فترة البرنامج</li>
               <li>زيارات دولية مثرية لنقل تجارب ناجحة من قطاع الترفيه</li>
               <li>ورش عمل مع أفضل الجهات التدريبية</li>
               <li>تكاليف الزيارات الدولية مغطاة</li>
              </ul>
            </p>
          </div>
        </div>

        <div className="col-lg-6 col-12 mt-3">
          <div
            className="p-3"
            style={{
              backgroundColor: "#FBA049",
              color: "white",
              height: "100%",
            }}
          >
            <div className="title d-flex align-items-center justify-content-end">
              <h4 className="d-inline mb-0 fw-bold">مدة البرنامج</h4>
              <img
                style={{ width: "20px", marginLeft: "10px" }}
                src={users}
                alt=""
              />
            </div>
            <p className="text-white fw-normal py-3" dir="rtl">
            مدة البرنامج 5 أشهر، لا يتطلب التفرغ التام ويلزم حضور عدد 3 ورش عمل والمشاركة في الزيارات الميدانية خارج المملكة لمدة 3 أسابيع
              </p>
          </div>
        </div>

        <div className="col-12 my-3">
          <div
            className="p-3"
            style={{
              backgroundColor: "#FBA049",
              color: "white",
              height: "100%",
            }}
          >
            <div className="title d-flex align-items-center justify-content-end">
              <h4 className="d-inline mb-0 fw-bold">معايير القبول</h4>
              <img
                style={{ width: "20px", marginLeft: "10px" }}
                src={checklist}
                alt=""
              />
            </div>
            <p className="text-white fw-normal py-3">
              <ul style={{ direction: "rtl", listStylePosition: "inside" }}>
                <li>سعودي/ة الجنسية</li>

                <li>
                  خبرة عملية تتراوح بين عامين وخمسة أعوام في القطاع الترفيهي أو
                  القطاعات الأخرى المشابهة
                </li>
                <li>
                أن يكون المتقدم حاصل على درجة لا تقل عن 5.0 في اختبار IELTS أو ما يعادلها، أو تقديم ما يثبت تمكّنه من اللغة الإنجليزية
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      <div className="row" style={{ 'width': 'max-content', 'padding-bottom': '20px' }}>
        <div className="col">
          <a href="https://takamol.disamina.in:443/candidates/live/login?examlink=9e861ef2-db45-457d-8fa3-d4b86548efc0" target="_blank">
          <button className="text-white" style={{ 'backgroundColor': 'rgb(251, 160, 73)', 'padding': '10px 30px', 'border': 'none', 'color': 'white', 'fontSize': '18px' }}>
            سجل الان
          </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Slide2;

import React from "react";


import geaWhite from '../assets/images/logos/gea-footer-logo.png'
import combinedLogos from '../assets/images/logos/combined-logo.png'
import geaLogo from './../assets/images/logos/gea-purple-green-logo.png'

import {
  FaFacebookF,
  FaLinkedinIn,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope
} from "react-icons/fa";
import { FaInstagram, FaXTwitter, FaYoutube } from "react-icons/fa6";
import { TfiEmail } from "react-icons/tfi";



const Footer = () => {
  return (



    <div className="footer">
      <div className="container">

        <div className="row justify-content-between align-items-center">

          <div className="col-lg-7 col-12 text-end">
            <div className="row d-flex justify-content-lg-evenly justify-content-end">
             
              <div className="col-md-auto col-12 text-md-center text-end me-4">
                <h5 className="text-white mb-0">اتصل بنا</h5>
                <br />
                <h5 className="text-white">8001282222</h5>

              </div>
             
              <div className="col-md-auto col-12 me-md-4 me-0 text-md-center text-end mt-4 mt-md-0">
                <h5 className="text-white">الأسئلة الشائعة</h5>
              </div>
              <div className="col-md-auto col-12 text-md-center text-end mt-4 mt-md-0">
                <h5 className="text-white">تواصل معنا عبر مواقع التواصل</h5>

                <div className="social-icons">
                  <a className="me-4"
                    href="https://www.linkedin.com/company/general-entertainment-authority/"
                    target="_blank"
                  >
                    <div className="info-social">
                      <FaLinkedinIn className="info-social-icon text-white fs-4" />
                    </div>
                  </a>
                  <a className="me-4"
                    href="https://www.youtube.com/channel/UCHJpU7eYVx5SUg48L31mfaQ"
                    target="_blank"
                  >
                    <div className="info-social">
                      <FaYoutube className="info-social-icon text-white fs-4" />
                    </div>
                  </a>
                  <a className="me-4"
                    href="https://twitter.com/gea_sa" target="_blank">
                    <div className="info-social">
                      <FaXTwitter className="info-social-icon text-white fs-4" />
                    </div>
                  </a>
                  <a
                    href="https://www.instagram.com/gea_saudi/"
                    target="_blank"
                  >
                    <div className="info-social">
                      <FaInstagram className="info-social-icon text-white fs-4" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-5 col-12 text-end">
            <img className="combined-logos" src={combinedLogos} alt="" />
            <img className="gea-logo ms-2" src={geaLogo} alt="" />
          </div>

        </div>


      </div>



      <div className="container mt-2 mt-md-5">

        <h4 className="copyright" dir="rtl">©جميع الحقوق محفوظة لصناع السعادة 2024</h4>
      </div>

    </div>

  )
}


export default Footer;